<template>
  <div class="main">
    <display-module :titleInfo="titleInfo" style="padding-top: 92px;margin-bottom:35px;" />
    <div class="procedureContent">
        <div class="lineBox">
            <a-icon class="icon" style="left: 16%;top: -8px;" type="double-right" />
            <a-icon class="icon" style="left: 49%;top: -8px;" type="double-right" />
            <a-icon class="icon" style="left: 82%;top: -8px;" type="double-right" />
            <a-icon class="icon" style="left: 16%;bottom: -8px;" type="double-left" />
            <a-icon class="icon" style="left: 49%;bottom: -8px;" type="double-left" />
            <a-icon class="icon" style="left: 82%;bottom: -8px;" type="double-left" />
            <a-icon class="icon" style="right: -8px;top: 65%;transform:rotate(-90deg);" type="double-left" />
        </div>
      <div class="procedureList">
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="项目洽谈" />
            <div class="Medium">项目洽谈</div>
          </div>
          <div class="procedureItemText">
            销售人员与客户进行项目谈判，确定初步意向，提交意向金
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="需求评估" />
            <div class="Medium">需求评估</div>
          </div>
          <div class="procedureItemText">
            提供解决方案，制作报价文档，30分钟获取报价方案
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="需求调研" />
            <div class="Medium">需求调研</div>
          </div>
          <div class="procedureItemText">
            需求分析师与客户沟通需求吗，整理需求清单
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="产品规划" />
            <div class="Medium">产品规划</div>
          </div>
          <div class="procedureItemText" style="height:51px;margin-bottom:30px;">
            需求规划，资料整理，原型图制作
          </div>
        </div>
      </div>
      <div class="procedureList">
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="部署上线" />
            <div class="Medium">部署上线</div>
          </div>
          <div class="procedureItemText">
            域名解析，服务器环境部署
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="测试交付" />
            <div class="Medium">测试交付</div>
          </div>
          <div class="procedureItemText">
            原型图、设计稿、源码、操作文档交付
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="程序开发" />
            <div class="Medium">程序开发</div>
          </div>
          <div class="procedureItemText">
            搭建系统架构，业务逻辑、功能开发
          </div>
        </div>
        <div class="procedureItem">
          <div class="procedureItemTop">
            <img-item class="imgitem" imgName="UI设计" />
            <div class="Medium">UI设计</div>
          </div>
          <div class="procedureItemText">
            设计分析，页面设计，体验优化 
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "procedure",
        CHSTitle: "服务流程",
        instructions: [
          "完善的产品研发流程，层层把关，全身心投入，只为客户满意",
        ],
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.main {
  width: 100%;
  position: relative;
}
.procedureContent {
  position: relative;
  width: 755px;
  margin: 0 auto;
}
.lineBox {
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 65px;
  right: 65px;
  height: 302px;
  border: 1px dashed #1A66FF;
  border-left: none;
}
.lineBox .icon{
    position: absolute;
    
    font-size: 15px;
    color: #1a66ff;
}
.procedureList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.procedureItem {
  margin: 0 38px;
  position: relative;
  z-index: 10;
}
.procedureItemTop {
  position: relative;
  z-index: 100;
  background: #fff;
  text-align: center;
}
.procedureItemTop > img {
  width: 69px;
  height: 69px;
  background: #fff;
}
.procedureItemTop .Medium {
  
  padding: 13px 0 11px;
  font-size: 18px;
  color: #3a416b;
  line-height: 25px;
  letter-spacing: 1px;
  background: #fff;
}
.procedureItemText {
  width: 132px;
  height: 81px;
  font-size: 15px;
  color: #6d738e;
  line-height: 20px;
  letter-spacing: 1px;
  background: #fff;
}
</style>
