<template>
  <div class="main">
    <img-item class="imgItem" imgName="编组 2" />
    <div class="text-part">
      <div class="viewTitle Medium" @click="clickTo()">
        10年时间 80位同事 3000个日夜
      </div>
      <div style="width: 100%">
        <div class="viewText">
          多创拥有一支经验丰富、眼光独到、自信尽职、专业创新的高端网站建设团队，凭着多年的实践经验，一直坚持高标准的服务。多创在为您做的，不仅仅是一次服务，而是与您一起，缔造一个属于您的网络品牌。
        </div>
      </div>
      <div class="dataContent">
        <div class="dataItem">
          <count-to
            :startVal="0"
            :endVal="500"
            :duration="3000"
            class="dataNum"
            v-if="showFlag"
          ></count-to>
          <div class="dataText">500+多家公司达成合作</div>
        </div>
        <div class="dataItem">
          <count-to
            :startVal="0"
            :endVal="1000"
            :duration="3000"
            class="dataNum"
            v-if="showFlag"
          ></count-to>
          <div class="dataText">1000+多万用户在使用中</div>
        </div>
        <div class="dataItem">
          <count-to
            :startVal="0"
            :endVal="2000"
            :duration="3000"
            class="dataNum"
            v-if="showFlag"
          ></count-to>
          <div class="dataText">2000+多项目部署上线</div>
        </div>
        <div class="dataItem">
          <count-to
            :startVal="0"
            :endVal="5000"
            :duration="3000"
            class="dataNum"
            v-if="showFlag"
          ></count-to>
          <div class="dataText">5000+多次会议沟通交流</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
import countTo from "vue-count-to";
export default {
  components: {
    DisplayModule,
    countTo,
  },
  props:{
    showFlag : Boolean
  },
  data() {
    return {};
  },
  methods: {
      clickTo(){
          
      }
  },
  created() {},
};
</script>
<style scoped>
.main {
  width: 100%;
  min-height: 718px;
  color: #fff;
  margin-left: -1px;
  text-align: center;
  position: relative;
}
.main .imgItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.main .text-part{
  position: absolute;
  width: 100%;
  z-index: 100;
}
.viewTitle {
  font-size: 36px;
  line-height: 49px;
  letter-spacing: 3px;
  margin: 0 0 16px;
  padding-top: 78px;
}
.viewText {
  width: 830px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
  margin: 0 auto;
}
.dataContent {
  display: flex;
  justify-content: space-around;
  margin: 0 15%;
  align-items: center;
}
.dataItem {
  margin: 142px 49px 80px;
}
.dataNum {
  font-size: 68px;
  color: #2b7bed;
  line-height: 92px;
  letter-spacing: 5px;
  font-family: SFUDINEngschrift;
  -webkit-text-stroke: 1px #2b7bed;
}
.dataText {
  margin-top: 13px;
  font-size: 15px;
  line-height: 21px;
}
</style>
