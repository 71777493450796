var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","position":"relative","overflow":"hidden"}},[_c('div',{staticClass:"demandLine"},[_c('a-icon',{staticClass:"demandLineIcon",attrs:{"type":"caret-down"}})],1),_c('div',{staticClass:"demandMainPart"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFlag),expression:"showFlag"}],staticClass:"demandLeft animate__animated animate__fadeInLeft"},[_c('div',{staticClass:"demandLeftTitle Medium"},[_vm._v("深入沟通，")]),_c('div',{staticClass:"demandLeftTitle Medium"},[_vm._v("只为更深入的合作")]),_c('div',{staticClass:"demandLeftContent"},[_vm._v(" 收到消息后，我们将在第一时间与您取得联系 ")]),_c('div',{staticClass:"formLine"}),_c('a-form',{attrs:{"form":_vm.form,"layout":"inline"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'fullName',
              { rules: [{ required: true, message: '请填写您的姓名' }] }
            ]),expression:"[\n              'fullName',\n              { rules: [{ required: true, message: '请填写您的姓名' }] }\n            ]"}],staticStyle:{"width":"248px","height":"46px"},attrs:{"placeholder":"姓名"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              { rules: [{ validator: _vm.checkPhone, trigger: 'blur' }] }
            ]),expression:"[\n              'phone',\n              { rules: [{ validator: checkPhone, trigger: 'blur' }] }\n            ]"}],staticStyle:{"width":"248px","height":"46px","margin-left":"10px"},attrs:{"placeholder":"联系电话"}})],1),_c('a-form-item',{staticStyle:{"padding":"10px 0"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'content',
              { rules: [{ required: true, message: '请填写您的需求或反馈' }] }
            ]),expression:"[\n              'content',\n              { rules: [{ required: true, message: '请填写您的需求或反馈' }] }\n            ]"}],staticStyle:{"width":"522px"},attrs:{"placeholder":"填写您的需求或反馈","rows":8}})],1),_c('a-form-item',[_c('a-button',{staticClass:"demandLeftBotton Medium",attrs:{"type":"primary"},on:{"click":_vm.subFeedBack}},[_vm._v(" 提交需求 ")])],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFlag),expression:"showFlag"}],staticClass:"demandRight animate__animated animate__fadeInRight"},[_c('img-item',{staticStyle:{"width":"100%","height":"auto"},attrs:{"imgName":"christiann-koepke-uyzMBs2KWCU-unsplash"}}),_c('div',{staticClass:"demandRightContent"},[_c('img-item',{attrs:{"imgName":"编组 3"}}),_vm._m(0)],1),_c('div',{attrs:{"id":"triangle-left"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-left":"30px"}},[_c('div',[_vm._v("因为您的信任 最终我们成为了朋友，")]),_c('div',[_vm._v("为朋友做事，我们两肋插刀")])])}]

export { render, staticRenderFns }