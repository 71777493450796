<template>
  <div style="position: relative">
    <a-carousel autoplay ref="varousel">
      <div class="carouselItem">
        <img-item imgName="banner1" />
        <div class="carouselTextPart">
          <div class="textPartTitle Medium">D-TRON</div>
          <div class="textPartTitle Medium">科技引领未来·共创卓越品质</div>
          <div class="textPartLine"></div>
          <div class="textPartContent">
            致力于为全球中小型企业提供专业，智能，客制化的产品和解决方案，携手企业共赢新时代！
          </div>
        </div>
      </div>
      <div class="carouselItem">
        <img-item imgName="austin-distel-rxpThOwuVgE-unsplash" />
        <div class="carouselTextPart">
          <div class="textPartTitle Medium">产品驱动·技术驱动·品牌驱动</div>
          <div class="textPartLine"></div>
          <div class="textPartContent">
            比起简单粗暴的定制开发，我们更希望与您基于经营场景、行业属性、用户行为。一同打造具备体验温度的好产品。
          </div>
        </div>
      </div>
      <div class="carouselItem">
        <img-item imgName="austin-distel-rxpThOwuVgE-unsplash (1)" />
        <div class="carouselTextPart">
          <div class="textPartTitle Medium">契合互联网产品思维</div>
          <div class="textPartLine"></div>
          <div class="textPartContent">
            基于客户切实业务需求，打造用户极致产品体验
          </div>
        </div>
      </div>
    </a-carousel>
    <div class="controlBotton">
      <div class="lastBotton" @click="prev()">
        <img-item imgName="jiantou" />
      </div>
      <div class="nextBotton" @click="next()">
        <div>
          <img-item imgName="jiantou" style="transform: rotateY(180deg)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    prev() {
      this.$refs.varousel.prev();
    },
    next() {
      this.$refs.varousel.next();
    },
  },
};
</script>

<style scoped>
.carouselItem {
  width: 100%;
  position: relative;
}
.carouselItem img {
  width: 100%;
  height: auto;
}
.carouselTextPart {
  position: absolute;
  top: 280px;
  left: 150px;
  color: #fff;
}
.textPartTitle {
  font-size: 54px;
  line-height: 68px;
}
.textPartContent {
  font-size: 27px;
  line-height: 38px;
  width: 669px;
}
.textPartLine {
  width: 68px;
  height: 6px;
  background: #2b7bee;
  margin: 13px 0;
}
.controlBotton {
  position: absolute;
  right: 0;
  bottom: 6px;
  display: flex;
  cursor: pointer;
}
.controlBotton img {
  width: 35px;
  height: 10px;
}
.lastBotton {
  padding: 21px 35px;
  background: #2b7bed;
}
.nextBotton {
  padding: 21px 35px;
  background: rgba(255, 255, 255, 0.5);
}
.lastBotton:hover img {
  /* x=-10px代表左移10px */
  transform: translateX(-10px);
  /* 0.4s完成transform移动效果*/
  transition: transform 1s;
}
.nextBotton:hover div {
  /* x=-10px代表左移10px */
  transform: translateX(10px);
  /* 0.4s完成transform移动效果*/
  transition: transform 1s;
}
</style>
