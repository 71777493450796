<template>
  <div class="main">
    <div class="serviceContent">
      <display-module :titleInfo="titleInfo" style="padding-top: 86px" v-show="showFlag"  />
      <div class="serviceList">
        <div v-show="showFlag" class="serviceListItem animate__animated animate__flipInY animate__slow" >
          <img-item imgName="移动APP定制开发" />
          <div class="serviceListItemTitle">移动APP定制开发</div>
          <div class="serviceListItemContent">
            Android、IOS系统开发，满足全行业类型需求
          </div>
        </div>
        <div v-show="showFlag" class="serviceListItem animate__animated animate__flipInY animate__slow">
          <img-item imgName="微信定制开发" />
          <div class="serviceListItemTitle">微信定制开发</div>
          <div class="serviceListItemContent">
            微信公众号、微信商城、H5开发、小程序开发
          </div>
        </div>
        <div v-show="showFlag" class="serviceListItem animate__animated animate__flipInY animate__slow">
          <img-item imgName="企业开发" />
          <div class="serviceListItemTitle">企业开发</div>
          <div class="serviceListItemContent">
            企业集团OA系统、开发CRM、ERP系统
          </div>
        </div>
        <div v-show="showFlag" class="serviceListItem animate__animated animate__flipInY animate__slow">
          <img-item imgName="网站开发" />
          <div class="serviceListItemTitle">网站开发</div>
          <div class="serviceListItemContent">
            公司官网定制开发，电商系统、社交平台等开发
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props:{
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "service",
        CHSTitle: "核心服务",
        instructions: [
          "丰富的产品设计经验+资深的开发能力，让您享受互联网大企业般的服务。",
        ],
      },
    };
  },
  methods: {
  },
  created() {
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  position: relative;
}
.serviceContent {
  width: 100%;
  background: #f5f6fb;
}
.serviceList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 38px 0 0;
  padding-bottom: 93px;
}
.serviceListItem {
  width: 246px;
  text-align: center;
  margin: 0 65px;
}
.serviceListItem img {
  width: 185px;
  height: 185px;
}
.serviceListItemTitle {
  font-size: 23px;
  color: #3a416b;
  line-height: 31px;
  letter-spacing: 1px;
}
.serviceListItemContent {
  width: 246px;
  margin: 22px auto 0;
  font-size: 15px;
  color: #6d738e;
  line-height: 20px;
}
</style>
