<template>
  <div class="main">
    <div class="bg-box1"></div>
    <div class="bg-box2"></div>
    <display-module :titleInfo="titleInfo" style="padding-top: 92px" v-show="showFlag"/>
    <div class="advantage-content">
      <div class="advantage-item animate__animated animate__fadeInUp animate__slow" v-show="showFlag">
        <div class="advantage-item-top">
          <img-item imgName="专业团队"/>
          <div class="medium">专业团队</div>
        </div>
        <div class="advantage-item-content">
          多创拥有众多大厂经验的软件开发工程师、项目经理。拥有丰富项目经验，开发过12306订票软件、建发国际ERP系统、智慧法院、B2B电商平台等项目。
        </div>
      </div>
      <div class="advantage-item animate__animated animate__fadeInUp animate__slow" v-show="showFlag">
        <div class="advantage-item-top">
          <img-item imgName="先进技术"/>
          <div class="medium">先进技术</div>
        </div>
        <div class="advantage-item-content">
          一线的技术团队支持,开发模式符合国际主流技术配置。设计能力水平媲美一线城市，多数是6年以上行业经验。
        </div>
      </div>
      <div class="advantage-item animate__animated animate__fadeInUp animate__slow" v-show="showFlag">
        <div class="advantage-item-top">
          <img-item imgName="严谨保护"/>
          <div class="medium">严谨保密</div>
        </div>
        <div class="advantage-item-content">
          和每个用户签订专门的《保密协议》，无论在服务期内还是服务期外，我们将严格遵守保密义务，保证客户的知识产权和竞争优势。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props:{
    showFlag:Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "advantage",
        CHSTitle: "我们的优势",
        instructions: [
          "我们时刻保持高度的责任感，敬业的精神，专业的素质。以“新技术、高效率、专业化”为核心。",
          "致力于为用户提供极致的使用体验，无忧的全包服务。与每一位客户建立长久良好的合作。",
        ],
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.main {
  width: 100%;
  position: relative;
}
.bg-box1 {
  position: absolute;
  top: -6px;
  left: 0;
  width: 468px;
  height: 689px;
  background: linear-gradient(180deg, #2b7bed 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.2;
  z-index: 1;
}
.bg-box2 {
  position: absolute;
  top: -6px;
  left: 0;
  width: 234px;
  height: 689px;
  background: linear-gradient(180deg, #2b7bed 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.3;
  z-index: 10;
}
.advantage-content {
  display: flex;
  justify-content: center;
  margin: 37px 0 86px;
  position: relative;
  z-index: 11;
}
.advantage-item {
  padding: 37px 60px 0;
  margin: 0 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  width: 345px;
  height: 365px;
}
.advantage-item-top {
  text-align: center;
}
.advantage-item-top > img {
  width: 72px;
  height: 72px;
}
.advantage-item-top > div {
  margin: 14px 0 17px;
  color: #3a416b;
  line-height: 29px;
  letter-spacing: 1px;
  font-size: 21px;
}
.advantage-item:hover {
  padding: 36px 59px 0;
  border: 2px solid #2b7bed;
  border-radius: 8px;
  height: 363px;
  box-shadow: 11px 11px 45px 0px rgba(50, 59, 70, 0.1);
}
.advantage-item-content {
  font-size: 15px;
  color: #6d738e;
  line-height: 30px;
}
</style>
