<template>
  <div class="main">
    <top-banner />
    <div ref="advantage">
      <advantage-part :showFlag="advantageShow"/>
    </div>
    <div ref="service">
      <service-part  :showFlag="serviceShow"/>
    </div>
    <div ref="procedure">
      <procedure-part :class="procedureShow? 'animate__animated animate__fadeInLeftBig': ''"/>
    </div>
    <div ref="dataScreen">
      <data-screen-part :showFlag="dataScreenShow"/>
    </div>
    <div ref="cooperation">
      <cooperation-part :showFlag="cooperationShow"/>
    </div>
    <div ref="demand">
      <demand-part :showFlag="demandShow"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
import TopBanner from "@/components/topBanner/index";
import advantagePart from "./components/advantage";
import servicePart from "./components/service";
import procedurePart from "./components/procedure";
import dataScreenPart from "./components/dataScreen";
import cooperationPart from "./components/cooperation";
import demandPart from "./components/demand";
export default {
  name: "home",
  components: {
    TopBanner,
    DisplayModule,
    advantagePart,
    procedurePart,
    dataScreenPart,
    cooperationPart,
    demandPart,
    servicePart
  },
  data() {
    return {
      dataScreenShow: false,
      serviceShow: false,
      cooperationShow: false,
      advantageShow: false,
      demandShow: false,
      procedureShow: false
    };
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () { // 实现当滚动到指定位置，触发动画
      let _this = this
      let refArray = [
        {ref: 'dataScreen', show: 'dataScreenShow'},
        {ref: 'service', show: 'serviceShow'},
        {ref: 'procedure', show: 'procedureShow'},
        {ref: 'advantage', show: 'advantageShow'},
        {ref: 'demand', show: 'demandShow'},
        {ref: 'cooperation', show: 'cooperationShow'},
      ]
      refArray.forEach((r,i) => {
         _this[r.show]= _this.gdjz(r.ref, 20)
      })
    },
    gdjz (div, offset) {
      let dom = this.$refs[div] 
      if(dom){
        var a,b,c,d;
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        return b + c > a
      }
    },
  },
  created() {},
};
</script>
<style scoped>
.main {
  width: 100%;
  position: relative;
}



</style>
