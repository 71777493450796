<template>
  <div style="width: 100%; position: relative;overflow:hidden;">
    <div class="demandLine">
      <a-icon type="caret-down" class="demandLineIcon" />
    </div>
    <div class="demandMainPart">
      <div
        class="demandLeft animate__animated animate__fadeInLeft"
        v-show="showFlag"
      >
        <div class="demandLeftTitle Medium">深入沟通，</div>
        <div class="demandLeftTitle Medium">只为更深入的合作</div>
        <div class="demandLeftContent">
          收到消息后，我们将在第一时间与您取得联系
        </div>
        <div class="formLine"></div>
        <a-form :form="form" layout="inline">
          <a-form-item>
            <a-input
              style="width: 248px; height: 46px"
              v-decorator="[
                'fullName',
                { rules: [{ required: true, message: '请填写您的姓名' }] }
              ]"
              placeholder="姓名"
            />
          </a-form-item>
          <a-form-item>
            <a-input
              style="width: 248px; height: 46px;margin-left:10px;"
              v-decorator="[
                'phone',
                { rules: [{ validator: checkPhone, trigger: 'blur' }] }
              ]"
              placeholder="联系电话"
            />
          </a-form-item>
          <a-form-item style="padding: 10px 0;">
            <a-textarea
              placeholder="填写您的需求或反馈"
              v-decorator="[
                'content',
                { rules: [{ required: true, message: '请填写您的需求或反馈' }] }
              ]"
              style=" width: 522px"
              :rows="8"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              class="demandLeftBotton Medium"
              @click="subFeedBack"
            >
              提交需求
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div
        class="demandRight animate__animated animate__fadeInRight"
        v-show="showFlag"
      >
        <img-item
          imgName="christiann-koepke-uyzMBs2KWCU-unsplash"
          style="width: 100%; height: auto"
        />
        <div class="demandRightContent">
          <img-item imgName="编组 3" />
          <div style="margin-left: 30px">
            <div>因为您的信任 最终我们成为了朋友，</div>
            <div>为朋友做事，我们两肋插刀</div>
          </div>
        </div>
        <div id="triangle-left"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { postAction } from "@/api/manage.js";
import crypto from "crypto";
export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      url: {
        list: "/http/helloword"
      }
    };
  },
  props: {
    showFlag: Boolean
  },
  methods: {
    checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        // console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    },
    subFeedBack() {
      var dingdingUrl =
        "/dingding/robot/send?access_token=19844ee7077dd3de1c37319d5973ce3f6ac4a320388c080ee97829d2b1a1b4e8";
      var secret =
        "SECbec77b7cac372d99ffa982ea27f72c68cc7b03badb12e388c782b2f975ab2acb";

      var timestamp = new Date().getTime();
      var stringToSign = timestamp + "\n" + secret;
      var base = crypto
        .createHmac("sha256", secret)
        .update(stringToSign)
        .digest("base64");
      var sing = encodeURIComponent(base);
      var dingdingStr = dingdingUrl + `&timestamp=${timestamp}&sign=${sing}`;
      console.log("dingdingStr", dingdingStr);

      var that = this;
      var time = new Date();
      this.form.validateFields((err, values) => {
        if (!err) {
          var data =
            "##### 新消息  \n  - **联系人**  \n  " + 
            values.fullName + 
            "  \n  - **联系方式**  \n  " + 
            values.phone +
            "  \n  - **内容**  \n  " + 
            values.content +
            "  \n  ###### " +
            time.toLocaleDateString() +
            " " +
            time.toLocaleTimeString();
            
          var params = {
            msgtype : 'markdown',
            markdown: {
              title: '来自官网的消息',
              text : data
            },

          }
          this.$confirm({
            title: "确认提交用户反馈?",
            content: "请仔细填写反馈，方便我们第一时间与您取得联系。",
            onOk() {
              postAction(dingdingStr, params).then(res => {
                that.form.resetFields;
              });
            },
            onCancel() {}
          });
        }
      });
    }
  },
  created() {}
};
</script>
<style scoped>
.demandLine {
  height: 8px;
  width: 100%;
  top: 0;
  left: 0;
  background: #2b7bed;
  z-index: 10;
  position: absolute;
}
.demandLineIcon {
  position: absolute;
  left: 50%;
  top: -8px;
  margin-left: -18px;
  color: #2b7bed;
  font-size: 36px;
}
.demandMainPart {
  display: flex;
  width: 100%;
}
.demandLeft {
  width: 50%;
  padding: 80px 76px 63px 122px;
}
.demandLeftTitle {
  font-size: 36px;
  color: #333333;
  line-height: 49px;
  letter-spacing: 3px;
}
.demandLeftContent {
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  letter-spacing: 1px;
  margin: 20px 0 68px;
}
.demandLeftBotton {
  width: 228px;
  background: #2b7bed;
  font-size: 15px;
  line-height: 45px;
  height: 45px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
}
.formLine {
  display: flex;
}
.demandRight {
  width: 50%;
  position: relative;
  z-index: 1;
}
.demandRightContent {
  position: absolute;
  top: 94px;
  left: -40px;
  display: flex;
  align-items: center;
}
.demandLeftContent img {
  width: 103px;
  height: 144px;
}
.demandRightContent div div {
  font-size: 18px;
  color: #164b97;
  line-height: 25px;
  letter-spacing: 2px;
}
#triangle-left {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -36px;
  width: 0;
  height: 0;
  border-top: 72px solid transparent;
  border-right: 63px solid #2b7bed;
  border-bottom: 72px solid transparent;
}
</style>
