<template>
  <div style="width:100%;background: #F5F6FB;overflow:hidden;">
    <display-module :titleInfo="titleInfo" style="padding-top: 92px" v-show="showFlag"/>
    <div class="cooperationContent animate__animated animate__fadeInLeft animate__slow" v-show="showFlag" >
      <img-item imgName="阿里云"/>
      <img-item imgName="蚂蚁金服"/>
      <img-item imgName="高德打车"/>
      <img-item imgName="首汽约车"/>
      <img-item imgName="滴滴"/>
    </div>
    <div class="cooperationContent animate__animated animate__fadeInRight animate__slow" v-show="showFlag">
      <img-item imgName="阳光出行"/>
      <img-item imgName="曹操出行"/>
      <img-item imgName="耀出行"/>
      <img-item imgName="12306"/>
      <img-item imgName="电信"/>
    </div>
    <div class="cooperationContent animate__animated animate__fadeInLeft animate__slow" v-show="showFlag" style="padding-bottom: 86px">
      <img-item imgName="KFc"/>
      <img-item imgName="M"/>
      <img-item imgName="中粮"/>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props:{
    showFlag:Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "cooperation",
        CHSTitle: "合作伙伴",
        instructions: [
          "服务客户500+，覆盖众多主流行业，更多客户正在加入我们的合作。"
        ],
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.cooperationContent{
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.cooperationContent > img {
    width: 218px;
    height: 105px;
}
.cooperationContent > div {
    width: 218px;
    height: 105px;
}
</style>
